/* You can add global styles to this file, and also import other style files */
/* @import "~bootstrap/dist/css/bootstrap.css";
@import "~tether/dist/css/tether.css";
@import "~bootstrap-table/dist/bootstrap-table.css";
@import "~font-awesome/css/font-awesome.css"; */

@font-face{
  font-family: 'Roboto-Light';
  font-stretch: condensed;
  font-style: normal;
  font-weight: 400;
  src:url('./assets/fonts/Roboto-Light.woff'),url('./assets/fonts/Roboto-Light.ttf')format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face{
  font-family: 'Roboto-Regular';
  font-stretch: condensed;
  src:url('./assets/fonts/Roboto-Regular.woff'),url('./assets/fonts/Roboto-Regular.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face{
  font-family: 'Roboto-dark';
  font-stretch: condensed;
  src:url('./assets/fonts/Munich-RE-Web-Light.woff'), url('./assets/fonts/Munich-RE-Web-Light.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/**Login page**/
.login-side-text{
  color: white;
  font-size: 23px;
  font-weight: bold;
  font-family: 'Roboto-dark';
}
/***/

.form-input{
  font-family: 'Roboto-Regular'!important;
  font-size: 15px;
}
.input-style,
.ngx-select__toggle,
.ngx-select__search {
  height: 55px !important;
  font-family: "Roboto-Light" !important;
  border: 1px solid rgba(151, 151, 151, 0.4117647058823529) !important;
  padding: 7px;
  border-radius: 0 !important;
  background-color: #f8f8f8 !important;
  font-size: 20px!important;
  color: #000000;
}
.input-font{
  font-size: large!important;
  font-family: "Roboto-Regular" !important;
  letter-spacing: -0.6px;
}

input.error-border, select.error-border, .error-border .ngx-select {
  border: 1px solid #ff0000!important;
}
.btn-style {
  color: #ffffff;
  background-color: #175788;
  border-radius: 0 !important;
  cursor: pointer;
}
.create-btn-style {
  color: #ffffff;
  background-color: #175788;
  border-radius: 25px !important;
  font-family: "Roboto-Light";
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 0.7;
  max-width: 100%;
  cursor: pointer;
}
.view-btn-style{
  color: #ffffff;
  background-color: #175788;
  border-radius: 0px !important;
  font-family: "Roboto-Light";
  font-size: large;
  font-weight: 600;
  text-align: center;
  line-height: 0.7;
  min-width: 130px;
  cursor: pointer;
  min-height: 53px;
}
.view-btn-style.danger{
  background-color: #841D1D !important;
}
.back-btn-style{
  color: #757575;
  background-color: #ffffff;
  border-radius: 0px !important;
  border: 2px solid;
  font-family: "Roboto-Light";
  font-size: large;
  font-weight: 600;
  text-align: center;
  line-height: 0.7;
  min-width: 130px;
  cursor: pointer;
  min-height: 53px;
}
.form-btn-style {
  font-family: "Roboto-dark" !important;
  color: #175788;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: medium!important;
  font-weight: 600;
}

.subHeaderFont {
  font-family: "Roboto-Light";
  color: #000000;
  font-size: xx-large!important;
}

.place-holder,
.text-muted,
.ngx-select__search {
  padding-top: 6px !important;
  font-size: 20px !important;
  color: #a2a2a2 !important;
}


.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 13px;
  color: #df0d0d;
  padding: 0px 7px !important;
  font-weight: 600;
}
.mandatory-star{
  width: 100%;
  margin-top: 0.25rem;
  color: #df0d0d;
  font-weight: 600;
}


/****dropdown***/

.dropdown-item {
  font-family: "Roboto-Light" !important;
  font-size: medium !important;
  /* font-weight: 600!important; */
  color: black !important;
}
.ngx-select__item_active {
  font-family: "Roboto-Light" !important;
  color:black !important;
  font-weight: bolder!important;
  background-color: #b1b1b1 !important;
}
.ngx-select__disabled{background-color: initial!important}
.input-style::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Roboto-Light" !important;
  font-size: 20px !important;
  color: #a2a2a2 !important;
}

.input-style::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Roboto-Regular" !important;
  font-size: 20px !important;
  color: #a2a2a2 !important;
}

.input-style::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Roboto-Light" !important;
  font-size: 20px !important;
  color: #a2a2a2 !important;
}

/****************model**********/
.modal-backdrop {
  z-index: 0 !important;
}


/****Bootstrap table***/

.pagination {
  display: block !important;
}
.pagination > li {
  float: left;
}
.pagination-detail {
  display: none !important;
}

.page-item.active .page-link {
  background-color: #175788;
  border-color: #175788;
}

.page-link {
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
}


.fixed-table-container thead th {
  border-left: none !important;
  border-bottom: 2px solid #ddd !important;
}
.fixed-table-container tbody td {
  vertical-align: middle;
  border-bottom: 1px solid #ddd !important;
}
.card-panel {
  border: none;
  box-shadow: none;
}

body {
  font-size: small;
  line-height: 1.42857143;
  color: #333;
  background-color: rgb(245, 245, 245);
  font-family: "Roboto-Light";
}
.table-head{
  font-size: small!important;
  font-family: 'Roboto-Regular';
}

.paginate_button.previous.disabled{
  color: rgba(0,0,0,.26);
  cursor: default;
  background-color: transparent;
}
.paginate_button.next.disabled{
  color: rgba(0,0,0,.26);
  cursor: default;
  background-color: transparent;
}
.paginate_button{
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "RobotoLight","Helvetica","Arial",sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}
.paginate_button.current{
  background: #175788;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}
td, th{
  border:none !important;
}
.fixed-table-container thead th .th-inner, .fixed-table-container tbody td .th-inner{
  padding:8px !important;
}
.bootstrap-table .table:not(.table-condensed) > tbody > tr > td{
  padding:8px !important
}
.bootstrap-table .table{
  font-size:13px;
}
.bootstrap-table .table thead > tr > th{padding: 0 26px 0px 0!important;}
.bootstrap-table .table:not(.table-condensed){
  background-color: #fbfbfb!important;
}
.table-responsive{
  -ms-overflow-style:scrollbar;
}
/*bootstrap table end*/



/***** toggle-enable disable*****/
.outerDivFull { margin:50px; }
.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 80px; max-width: 80px; height: 27px; background: #d1d1d1; display: block; border-radius: 100px; position: relative;top:3px }
.switchToggle label:after {content: ''; position: absolute; top: 5px; left: 5px; width: 15px; height: 15px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #175788; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: 'DeActive'; position: absolute; top: 5px; left: 25px; width: 25px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'Active'; position: absolute; top: 5px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 60px; }
.toggle-switchArea { margin: 10px 0 10px 0; }


/***date picker**/
.mydp {
  line-height: 1.1;
  display: block !important;
  position: relative !important;
  width: 260px;
  height: 55px !important;
  font-family: "Roboto-Light" !important;
  border: 1px solid rgba(151, 151, 151, 0.4117647058823529) !important;
  padding: 7px;
  border-radius: 0 !important;
  background-color: #f8f8f8 !important;
  font-size: large!important;
  color: #000000;
  width: 100%!important;
}
.mydp .selection{
  background-color: #f8f8f8 !important;
  font-family: "Roboto-Light" !important;
  font-size: 20px !important;
  color: #000000 !important;
  padding-left: 0px!important;
  padding-top: 10px!important;
}
.mydp .btnclear, .mydp .btndecrease, .mydp .btnincrease, .mydp .btnpicker, .mydp .headertodaybtn{
  background-color: #f8f8f8 !important;
}
.btnpicker{
  padding-top: 5px!important;
}
.mydp .selectiongroup{
  background-color: #f8f8f8!important;
}
.mydp .selectiongroup input{
  height: 30px!important;
}
.mydp .icon-mydpright:before { font-family: mydatepicker !important; }
.mydp .icon-mydpleft:before { font-family: mydatepicker !important; }
.mydp .icon-mydpup:before { font-family: mydatepicker !important; }
.mydp .icon-mydpdown:before { font-family: mydatepicker !important; }
/****upload-rate generation input file type ****/
input[type="file"]::-webkit-file-upload-button {
  color: #ffffff !important;
  background-color: #175788 !important;
  border-radius: 25px !important;
  font-family: "Roboto-Light" !important;
  font-size: smaller !important;
  font-weight: 600 !important;
  text-align: center !important;
  line-height: 0.7 !important;
  min-width: 100px!important;
  min-height: 30px !important;
  cursor: pointer !important;
}
input:focus {
  outline:none!important;
}

/*** upload end****/

button, input, optgroup, select, textarea{
  font-family: "Roboto-Light"!important;
}

  .tooltip{
    opacity: 1!important;
  }
  .tooltip [appTooltip] {
	display: inline-block;
	margin: 50px 20px;
	width: 180px;
	height: 50px;
	border: 1px solid gray;
	border-radius: 5px;
	line-height: 50px;
	text-align: center;
  }
  .ng-tooltip {
	position: absolute;
	max-width: 200px;
	font-size: 11px;
	text-align: center;
	color: #fff;
	padding: 12px 8px;
	background: #175788;
	border-radius: 4px;
	z-index: 2000;
	opacity: 0;
  }
  .ng-tooltip:after {
	content: "";
	position: absolute;
	border-style: solid;
  }
  .ng-tooltip-top:after {
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-color: #175788 transparent transparent transparent;
  }
  .ng-tooltip-bottom:after {
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-color: transparent transparent black transparent;
  }
  .ng-tooltip-left:after {
	top: 50%;
	left: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-color: transparent transparent transparent black;
  }
  .ng-tooltip-right:after {
	top: 50%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-color: transparent black transparent transparent;
  }
  .ng-tooltip-show {
	opacity: 1;
  }


/********************Container body, Pop up - All pages************************/

.container-body{
  padding:40px 40px 40px 40px;
  width: 65%;
  box-shadow: 0px 7px 45px -10px #b6b6b6;
  top: 9.6rem;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

form{
  padding: 10px!important;
  width: 100%;
}
.formgroup label {
  margin-bottom: unset;
}

.container-body-create{
  padding: 40px 40px 40px 40px;
  width: 65%;
  top: 7rem;
  background-color: #fff;
}

/*****************pop up***********/
.bg_container{
  background: rgba(0,0,0,0.5);
  position:fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  overflow-y: scroll;
  z-index:1111;

}

.container_inner{
  margin: 0 auto;
  width: 70%;
}
.bgColor {
  background-color: #f1f1f1!important;
  border-radius: 10px;
}
.border{
  border-radius: 10px;
}
.headerFont{
  font-size: 28px;
}

.popup_close{
  position: absolute;
  top: 45px;
  right: 25px;
  cursor: pointer;
}
/**********************container end******************/
.mt-style{
  margin-top: 1rem;
}
.fixed-height{
  min-height: 7rem;
}
/************************************************Media Query**************************************/
/**laptop small**/
@media only screen
and (min-width : 768px)
and (max-width : 1024px)  {
  .bootstrap-table .table:not(.table-condensed) > tbody > tr > td {
    padding: 3px !important;
}
.input-style, .ngx-select__toggle, .ngx-select__search {
  height: 45px !important;
  font-size: 12px!important;
}
.input-font{font-size: small!important;}
  .mydp{height: 45px !important; font-size: 12px!important;}
  .place-holder,
  .text-muted,
  .ngx-select__search {
    font-size: 12px !important;
    padding-top: 7px !important;
  }
  .dropdown-item{font-size: 12px!important}
  .mydp .selection{font-size: 12px !important;padding-top:0px!important;}
  .input-style::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 12px !important;
  }

  .input-style::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 12px !important;
  }

  .input-style::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 12px !important;
  }
  .fixed-table-container thead th .th-inner, .fixed-table-container tbody td .th-inner{
    padding: 3px !important;
  }
  .invalid-feedback{
    font-size: 9px!important;
  }
  .mt-style{
    margin-top: 0.5rem;
  }
  .fixed-height{
    min-height: 6rem;
  }
  .browse-btn{
    font-size: 8px;
  }
  .view-btn-style,.back-btn-style{
    min-height: 44px;
    font-size: small;
    min-width: 80px;
  }
  .switchToggle label{
    height: 20px;
  }
  .switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before{
    top: 2px;
  }
  .switchToggle input + label:before, .switchToggle input + input + label:before{top: 1px;}
  .switchToggle label:after{top: 2px;}
  .subHeaderFont{
    font-size: large!important;
  }
}
/**laptop common***/
@media only screen
and (min-width: 1030px)
and (max-width: 1368px) {}

@media only screen
and (min-width: 1024px)
and (max-width: 1368px){
  .bootstrap-table .table:not(.table-condensed) > tbody > tr > td {
      padding: 5px !important;
  }
  .input-style, .ngx-select__toggle, .ngx-select__search {
    height: 45px !important;
    font-size: 16px!important;
  }
  .input-font{font-size: medium!important;}
  .mydp{height: 45px !important; font-size: 16px!important;}
  .place-holder,
  .text-muted,
  .ngx-select__search {
    font-size: 16px !important;
  }
  .dropdown-item{font-size: 12px!important}
  .mydp .selection{font-size: 16px !important;padding-top:0px!important;}
  .input-style::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 16px !important;
  }

  .input-style::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px !important;
  }

  .input-style::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 16px !important;
  }
  .fixed-table-container thead th .th-inner, .fixed-table-container tbody td .th-inner{
    padding: 5px !important;
  }
  .invalid-feedback{
    font-size: 11px!important;
  }
  .mt-style{
    margin-top: 0.8rem;
  }
  .fixed-height{
    min-height: 6rem;
  }
  .browse-btn{
    font-size: 10px;
  }
  .view-btn-style,.back-btn-style{
    min-height: 43px;
    font-size: small;
    min-width: 100px;
  }
  .create-btn-style{
    font-size: small;
  }
  .switchToggle label{
    height: 22px;
  }
  .switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before{
    top: 2px;
  }
  .switchToggle input + label:before, .switchToggle input + input + label:before{top: 2px;}
  .switchToggle label:after{top: 3px;}
  .subHeaderFont{
    font-size: x-large!important;
  }
  #dropZone{
    height: 165px!important;
  }
}

#dropZone{
  height: 190px;
}
.nvtooltip{
  top: 0px;
  left: 0px;
  opacity: 0;
  position: fixed;
  color: white !important;
  font-size: 15px !important;
  background: rgb(81, 137, 180) !important;
  border: 1px solid rgb(23, 87, 136) !important;
  transform: translate(1084px, 735px) !important;
}

.tick line {
  visibility:visible!important;
  display: block!important;
  opacity: 1!important;
  }
  .line {
    fill: none;
    stroke: MidnightBlue;
    stroke-width: 2px;
  }
    .dot {
      fill: IndianRed;
    }
    .area {
      fill: none;
    }
    text.shadow {
      stroke: white;
      stroke-width: 4px;
      opacity: 1
    }
    .grid line {
      stroke: lightgrey;
      stroke-opacity: 0.6;
      shape-rendering: crispEdges
    }
    .grid path {
      stroke-width: 0;
    }
